<template>
  <div class="user-teaser" :class="'user-'+student.student_status">
    <b-row class="align-items-center">
      <b-col cols="auto" class="opacity-changed">
        <router-link :to="{ name: linkName, params: {id: student.id} }">
          <LetterIcon :obj="student" :title="student.name" class="student-icon"/>
          <div v-if="hasUnreviewedHomework" class="homework-icon">
            <HomeworkSvg/>
          </div>
        </router-link>
      </b-col>
      <b-col col>
        <div class="pl-3  opacity-changed">
          <router-link :to="{ name: linkName, params: {id: student.id} }">
            <h3 class="mb-1">{{ student.name }}</h3>
          </router-link>
          <div class="d-block d-lg-none">Id {{ student.id }}</div>
          <div class="text-muted small text-capitalize">{{ student.level }}</div>
          <div v-if="$store.state.user.primary_role_id == 4 && student.teacher">{{ student.teacher.name }}</div>
        </div>
      </b-col>
      <b-col lg="2" cols="3" class="d-lg-block d-none">
        Id {{ student.id }}
      </b-col>
      <b-col lg="2" cols="12" class="opacity-changed mt-3 mt-lg-0">
        <div v-if="student.student_status != 'active'">
          {{ $t(student.student_status) }}
        </div>
        <div v-else>
          <div v-for="(sl, slind) in student.student_lessons" :key="'student-lesson-'+student.id+'-'+slind" class="d-block">
            <span class="text-capitalize">{{ sl.start | moment('ddd') }}</span>
            <span class="ml-2 text-muted">{{ sl.start | moment('HH:mm') }}</span>
          </div>
          <div v-if="!student.student_lessons || student.student_lessons.length < 1" class="text-muted">{{ $t('schedule_is_empty') }}</div>
        </div>
      </b-col>
      <b-col lg="2" col class="opacity-changed">
        <b-row>
          <b-col cols="auto" class="d-lg-block d-none">
            <WarningSvg v-if="moneyDanger"/>
          </b-col>
          <b-col>
            <div :class="{ 'text-danger': moneyDanger }" v-if="student.lessonsForBalance !== null">
              {{ student.lessonsForBalance }} {{ $t('les_left') }}
            </div>
            <div class="text-muted small">
              {{ student.balance | formatNumber }} {{ $store.state.user.currency }}
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="2" cols="auto">
        <LetterIcon passedClass="group-icon" :customTitle="student.groups.length" class="student-icon xsm pointer"  :id="`group-${student.id}`"/>
        <b-popover :target="`group-${student.id}`" triggers="hover" placement="top">
          <div class="font-weight-bold pb-2">{{ $t('groups_to_which_student_is_added') }}: <span class="text-primary">{{ student.groups.length }}</span></div>
          <div v-for="group in student.groups" :key="`gr-${group.id}`">
            <hr class="my-2"/>
            <b-row class="align-items-center pointer" @click="$emit('editGroup', group, true)">
              <b-col cols="auto">
                <LetterIcon passedClass="group-icon" class="student-icon xsm pointer" :title="group.title"/>
              </b-col>
              <b-col col class="font-weight-bolder">{{ group.title }}</b-col>
            </b-row>
          </div>
        </b-popover>
      </b-col>
      <b-col lg="auto" cols="auto">
        <div class="text-right">
          <b-dropdown right no-caret menu-class="noscrollbar" variant="link" class="d-inline-block pointer">
            <template v-slot:button-content>
              <MoreSvg class="d-inline-block"/>
            </template>
            <b-dropdown-item v-if="student.student_status == 'active'" @click="editStudent">{{ $t('edit') }}</b-dropdown-item>
            <b-dropdown-item v-if="student.student_status == 'active'" @click="passwordReset">{{ $t('reset_password') }}</b-dropdown-item>
            <b-dropdown-item v-if="$store.state.user.can_admin_payments && student.student_status == 'active'" @click="addPayment">{{ $t('add_payment') }}</b-dropdown-item>
            <b-dropdown-item v-if="student.blocked" @click="resendInv(student.id)">{{ $t('resend_invite') }}</b-dropdown-item>
            <b-dropdown-item v-if="student.student_status == 'active'" @click="pauseStudent">{{ $t('pause_student') }}</b-dropdown-item>
            <b-dropdown-item v-if="student.student_status == 'paused'" @click="activateStudent">{{ $t('activate_student') }}</b-dropdown-item>
            <b-dropdown-item v-if="student.student_status == 'paused'" @click="archiveStudent">{{ $t('archive_student') }}</b-dropdown-item>
            <b-dropdown-item @click="addNote">{{ $t('add_note') }}</b-dropdown-item>
            <b-dropdown-item v-if="student.student_status == 'active'" :to="{ name: 'Schedule', query: { students: student.id }}">{{ $t('schedule') }}</b-dropdown-item>
            <b-dropdown-item v-if="[4].includes(primaryRoleId)" @click="deleteStudent" >{{ $t('delete') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MoreSvg from '@/assets/svg/more.svg'
import HomeworkSvg from '@/assets/svg/homework.svg'
import WarningSvg from '@/assets/svg/warning.svg'
import LetterIcon from '@/views/parts/general/LetterIcon'
import { StudentsService } from '@/services/api.service'

export default {
  components: {
    MoreSvg,
    WarningSvg,
    LetterIcon,
    HomeworkSvg
  },
  data() {
    return {
      loading: false
    }
  },
  props: {
    student: {}
  },
  methods: {
    editStudent() {
      this.$emit('edit', this.student)
    },
    pauseStudent() {
      this.changeStudentStatus('paused')
    },
    resendInv(id) {
      StudentsService.resendInvite(id)
          .then(() => {
            this.$toast(this, this.$t('invitation_sent_to_student'))
          })
          .catch('ERROR')
    },
    activateStudent() {
      this.changeStudentStatus('active')
    },
    archiveStudent() {
      this.changeStudentStatus('archived')
    },
    changeStudentStatus(val) {
      StudentsService.updateStatus(this.student.id, val).then(() => {
        this.$emit('refresh')
      })
    },
    addNote() {
      this.$input(this.$t('student_note'),this.$t('note'), this.student.note).then((val) => {
        StudentsService.updateNote(this.student.id, val)
      })
    },
    addPayment() {
      this.$emit('addpay')
    },
    deleteStudent() {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        this.loading = true
        StudentsService.delete(this.student.id).then(() => {
          this.$success(this.$t('student_deleted'))
          this.$emit('refresh')
          this.loading = false
        }).catch((err) => {
          this.errResponse(err)
          this.loading = false
        })
      })
    },
    async passwordReset() {
      if(!this.student) return
      let res = await StudentsService.resetPassword(this.student.id)
      let newPass = res.data.data
      if(newPass) {
        this.$store.commit('setUserInvitationModalData', {
          password: newPass,
          emailSent: false,
          login: this.student.email
        })
        this.$bvModal.show('invitationsentmodal')
      }
    }
  },
  watch: {

  },
  mounted() {

  },
  computed: {
    linkName() {
      if(this.$store.state.user && [4,5].includes(this.$store.state.user.primary_role_id)) {
        return 'SchoolStudentHistory'
      }
      return 'StudentHistory'
    },
    primaryRoleId() {
      return this.$store.state.user ? this.$store.state.user.primary_role_id : null
    },
    hasUnreviewedHomework() {
      if(!this.student || !this.student.unreviewed_student_lessons_count) return false
      return this.student.unreviewed_student_lessons_count > 0
    },
    moneyDanger() {
      return Number(this.student.lessonsForBalance) <= 1
    }
  }

}
</script>

<style scoped lang="scss">
.homework-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>






