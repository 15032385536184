<template>
  <b-modal id="addgroupmodal" modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('new_group') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <h3>{{ $t('general_information') }}</h3>
    <InputGroup :label="$t('title')" class="default" v-model="form.title"/>
    <InputGroup :label="$t('description')" class="default" v-model="form.description"/>
    <div v-if="pricepolicies.length > 0">
      <h3 class="d-flex align-items-center">
        {{ $t('price_policy') }}
        <HelpSvg class="help ml-1" :hover="showTooltip = true" id="p_policy"/>
      </h3>
      <b-popover v-if="showTooltip" target="p_policy" triggers="hover" placement="top" custom-class="gray-popover">
        {{ this.$t('price_policy_info') }}
      </b-popover>
      <SelectGroup class="default" v-model="form.pricepolicy_id" :options="pricepolicies" title="title_price"/>
    </div>

    <div>
      <div class="spacecard md gray">
        <div v-b-toggle="'jaas'" class=" position-relative card-collapse">
          <b-row class="align-items-center">
            <b-col cols="auto">
              <img :src="`/images/jaas.png`" style="max-width: 64px"/>
            </b-col>
            <b-col>
              <h3 class="mb-1 pt-0">Jitsi meet (8x8)</h3>
              <div class="small text-muted">Video calls</div>
            </b-col>
            <b-col cols="auto">
              <span class="collapse-caret"><DownSvg/></span>
            </b-col>
          </b-row>
          <span class="position-absolute on-bubble"
                style="right: 30px; top: 50%; transform: translateY(-50%);"
                :class="{ active: form.meta_data.jaasOn }"
                @click.stop="form.meta_data.jaasOn = !form.meta_data.jaasOn">
              {{ form.meta_data.jaasOn ? $t('on') : $t('off') }}
          </span>


        </div>
        <b-collapse id="jaas">
          <div class="mt-4">
            <label>{{ $t('appId') }}</label>
            <InputGroup :label="$t('appId')" class="default" v-model="form.meta_data.jaasAppId"/>
            <label>{{ $t('keyId') }}</label>
            <InputGroup :label="$t('keyId')" class="default" v-model="form.meta_data.jaasKeyId"/>
            <label>{{ $t('privateKey') }}</label>
            <AutosizeTextArea :minH="100"
                              :maxH="200"
                              class="form-control-white"
                              v-model="form.meta_data.jaasPrivateKey"/>
          </div>
        </b-collapse>
      </div>
    </div>

    <div v-if="[4,5].includes($store.state.user.primary_role_id) && teachers.length > 0">
      <h3>{{ $t('teacher') }}</h3>
      <SelectGroup class="default" @input="changedTeacher" v-model="form.instructor_id" :options="teachers" title="name"/>
    </div>
    <div v-if="$store.state.user.primary_role_id != 4 || form.instructor_id">
      <h3>{{ $t('add_student') }}</h3>
      <StudentSearch @selected="studentsSelected"
                     :onlyactive="true"
                     :key="'studs-'+studSearchKey"
                     :teacher="[5].includes(roleId) ? null : parseInt(form.instructor_id)"
                     :dropdown="true"
                     :passedstudents="form.students"/>
    </div>
    <template v-slot:modal-footer>
      <b-button v-if="form.id" class="btn-themed btn-white btn-secondary" :disabled="loading" @click="destroy"><TrashSvg/></b-button>
      <b-button class="btn-themed" :disabled="!valid || loading" @click="save">{{ $t('save') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import HelpSvg from "@/assets/svg/help.svg"
import TrashSvg from '@/assets/svg/trash2.svg'
import CloseSvg from '@/assets/svg/close.svg'
import DownSvg from "@/assets/svg/down.svg"
import InputGroup from "@/views/parts/general/form/InputGroup"
import SelectGroup from "@/views/parts/general/form/SelectGroup"
import { StudentsService, PricePoliciesService, TeachersService } from '@/services/api.service'
import StudentSearch from '@/views/parts/class/StudentSearch'
import AutosizeTextArea from "@/views/parts/general/form/AutosizeTextArea.vue"

export default {
  components: {
    CloseSvg,
    TrashSvg,
    InputGroup,
    StudentSearch,
    SelectGroup,
    HelpSvg,
    DownSvg,
    AutosizeTextArea
  },
  data() {
    return {
      form: {
        id: "",
        title: "",
        description: "",
        students: [],
        instructor_id: null,
        pricepolicy_id: null,
        meta_data: {
          jaasOn: false,
          jaasAppId: '',
          jaasKeyId: '',
          jaasPrivateKey: '',
        }
      },
      pricepolicies: [],
      teachers: [],
      loading: false,
      studSearchKey: 0,
      showTooltip: false
    }
  },
  props: {
    group: {}
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === "addgroupmodal") {
        if(this.group) {
          this.form = {
            id: this.group.id,
            title: this.group.title,
            description: this.group.description,
            instructor_id: this.group.instructor_id,
            students: this.group.students.map(a => a.id),
            pricepolicy_id: this.group.pricepolicy_id,
            meta_data: this.group.meta_data || {
              jaasOn: false,
              jaasAppId: '',
              jaasKeyId: '',
              jaasPrivateKey: '',
            }
          }
        } else {
          this.form = {
            id: "",
            title: "",
            description: "",
            students: [],
            pricepolicy_id: null,
            meta_data: {
              jaasOn: false,
              jaasAppId: '',
              jaasKeyId: '',
              jaasPrivateKey: '',
            }
          }
        }
      }
    })
    PricePoliciesService.get().then(res => {
      this.pricepolicies = res.data.data
    })
    if([4,5].includes(this.$store.state.user.primary_role_id)) {
      TeachersService.get({ for_select: true }).then(res => {
        this.teachers = res.data.data
      })
    }
  },
  methods: {
    save() {
      this.loading = true
      if(this.form.id) {
        StudentsService.updateGroup(this.form.id, this.form).then(res => {
          let r = this.apiResponse(res)
          this.loading = false
          if(r.success) {
            this.$bvModal.hide('addgroupmodal')
            this.$success(this.$t('group_changed'))
            this.$emit('refresh')
          } else {
            this.$error(r.data)
          }
        }).catch(err => {
          this.errResponse(err)
          this.loading = false
        })
      } else {
        StudentsService.addGroup(this.form).then(res => {
          let r = this.apiResponse(res)
          this.loading = false
          if(r.success) {
            this.$bvModal.hide('addgroupmodal')
            this.$success(this.$t('group_added'))
            this.$emit('refresh')
          } else {
            this.$error(r.data)
          }
        }).catch(err => {
          this.errResponse(err)
          this.loading = false
        })
      }

    },
    studentsSelected(students) {
      this.form.students = students.map(a => a.id)
    },
    destroy() {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        this.loading = true
        StudentsService.deleteGroup(this.form.id).then(() => {
          this.loading = false
          this.$success(this.$t('group_deleted'))
          this.$bvModal.hide('addgroupmodal')
          this.$emit('refresh')
        }).catch((err) => {
          this.errResponse(err)
          this.loading = false
        })
      })
    },
    changedTeacher() {
      // console.log("teacher")
      // this.$set(this.form, 'students', [])
      // this.studSearchKey = Math.random()
    }
  },
  computed: {
    valid() {
      return this.form.title && this.form.students.length > 0
    },
    roleId() {
      return this.$store.state.user ? this.$store.state.user.primary_role_id : 2
    },
  }
}
</script>

<style scoped lang="scss">

</style>


