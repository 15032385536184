<template>
  <section id="students">

    <h1 id="page-title">{{ $t('students') }}</h1>

    <div class="tabs-navigator">
      <a :class="{ active: view == 'students' }" @click="view = 'students'">{{ $t('students') }}</a>
      <a :class="{ active: view == 'groups' }" @click="view = 'groups'">{{ $t('groups') }}</a>
    </div>

    <div>
      <div class="spacecard mb-4">
        <b-row class="row-wide">
          <b-col>{{ $t(view) }}</b-col>
          <b-col cols="auto">
            <span class="text-muted small" v-if="paginator">{{ $t(view) }}: {{ paginator.total}}</span>
          </b-col>
        </b-row>

        <div class="search-input-wrapper mt-4 mb-3">
          <SearchSvg class="position-absolute search-icon pointer" @click="search"/>
          <input class="search-form-control"
                 @keyup.enter="search"
                 :placeholder="$t('full_student_search')"
                 v-model="q"/>
        </div>

        <div v-if="view === 'students'">
          <a class="btn-add mb-24" @click="addStudent"><PlusSvg class="mr-2"/>{{ $t('add_student') }}</a>
          <StudentTeaser v-for="(s, sind) in list"
                         @edit="editStudent"
                         @editGroup="editGroup"
                         @refresh="reload"
                         @addpay="addpay(s)"
                         :student="s"
                         :key="'student-'+sind"/>
        </div>
        <div v-if="view === 'groups'">
          <a class="btn-add mb-24" @click="newGroup"><PlusSvg class="mr-2"/>{{ $t('add_group') }}</a>
          <GroupTeaser v-for="(s, sind) in list"
                       @edit="editGroup"
                       @refresh="reload"
                       :group="s"
                       :key="'group-'+sind"/>
        </div>
      </div>
      <Paginator :paginator="paginator" ref="pag"/>
    </div>
    <AddStudentModal @refresh="reload"
                     v-if="addstudentmodal"
                     :student="addingnewstudent ? null : selectedStudent"/>
    <InvitationSentModal user="student"/>
    <AddPaymentModal
        :passedstudents="selectedStudent ? [selectedStudent.id] : []"
        @refresh="reload"/>
    <AddGroupModal
        v-if="addgroupmodal"
        :group="addingnewgroup ? null : groupSelected"
        @refresh="getGroups"/>
  </section>
</template>

<script>
import AddStudentModal from '@/views/pages/instructor/students/AddStudentModal'
import AddGroupModal from '@/views/pages/instructor/students/AddGroupModal'
import AddPaymentModal from "@/views/pages/instructor/payments/AddPaymentModal"
import StudentTeaser from '@/views/teasers/StudentTeaser'
import GroupTeaser from '@/views/teasers/GroupTeaser'
import { StudentsService } from '@/services/api.service'
import EventBus from '@/services/event-bus'
import PlusSvg from '@/assets/svg/plus3.svg'
import SearchSvg from '@/assets/svg/search.svg'
import Paginator from "@/views/parts/general/Paginator"
import InvitationSentModal from "@/views/pages/instructor/students/InvitationSentModal"

export default {
  components: {
    InvitationSentModal,
    AddPaymentModal,
    Paginator,
    StudentTeaser,
    GroupTeaser,
    AddStudentModal,
    AddGroupModal,
    PlusSvg,
    SearchSvg
  },
  metaInfo() {
    return {
      title: this.$t('students'),
    }
  },
  data() {
    return {
      paginator: null,
      addstudentmodal: false,
      addgroupmodal: false,
      teacher: null,
      groupSelected: null,
      addingnewgroup: false,
      addingnewstudent: false,
      selectedStudent: null,
      page: this.$route.query.page ? this.$route.query.page : 1,
      view: 'students',
      q: ''
    }
  },
  mounted() {
    this.reload()
    EventBus.$emit('breadcrumbs',[
      { to: '/students', title: this.$t('students') },
    ])
  },
  computed: {
    list() {
      return this.paginator?.data || []
    }
  },
  methods: {
    getStudents() {
      StudentsService.get({ teacher: this.teacher, q: this.q }, this.page).then(res => {
        this.paginator = res.data.data
      }).catch(err => {
        console.log(err)
      })
    },
    getGroups() {
      StudentsService.getGroups({ q: this.q }, this.page).then(res => {
        this.paginator = res.data.data
      })
    },
    reload() {
      if(this.view === 'students') this.getStudents()
      if(this.view === 'groups') this.getGroups()
    },
    async newGroup() {
      // if(this.allstudents.length < 2) {
      //   this.$error(this.$t('you_need_to_create_at_least_two_students_to_form_group'))
      //   return
      // }
      this.addingnewgroup = true
      this.addgroupmodal = true
      await this.$nextTick()
      this.$bvModal.show('addgroupmodal')
    },
    async editGroup(group, reloadGroup = false) {
      if(reloadGroup) {
        group = await StudentsService.getGroups({ids: [group.id]})
        group = group.data?.data?.data?.[0] || null
        if(!group) return
      }
      this.groupSelected = group
      this.addingnewgroup = false
      this.addgroupmodal = true
      await this.$nextTick()
      this.$bvModal.show('addgroupmodal')
    },
    async addStudent() {
      this.addingnewstudent = true
      this.addstudentmodal = true
      await this.$nextTick()
      this.$bvModal.show('addstudentmodal')
    },
    async editStudent(student) {
      this.selectedStudent = student
      this.addingnewstudent = false
      this.addstudentmodal = true
      await this.$nextTick()
      this.$bvModal.show('addstudentmodal')
    },
    addpay(student) {
      this.selectedStudent = student
      this.$bvModal.show('addpaymentmodal')
    },
    search() {
      this.paginator = null
      if(Number(this.page) !== 1) {
        this.$router.push({ path: this.$route.path, query: { page: 1 }})
      } else {
        this.reload()
      }
    }
  },
  watch: {
    $route() {
      if(this.$route.query.page !== this.page) {
        this.page = this.$route.query.page
        this.reload()
      }
    },
    view() {
      this.paginator = null
      this.q = ''
      if(Number(this.page) !== 1) {
        this.$router.push({ path: this.$route.path, query: { page: 1 }})
      } else {
        this.reload()
      }
    }
  },
}
</script>

<style scoped lang="scss">
.btn-add {
  text-align: center;
  color: $text-themed;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #EDF0F7;
  font-weight: 700;
  cursor: pointer;
  border-radius: 16px;
  &:hover {
    border: 1px solid #43B4EB;
    text-decoration: none;
  }
}
@media(max-width: 768px) {
  .btn-add {
    height: 46px;
  }
}
</style>


