<template>
  <div class="user-teaser">
    <b-row class="align-items-center">
      <b-col lg="auto" cols="3" class="opacity-changed">
        <LetterIcon passedClass="group-icon"
                    :title="group.title"
                    class="student-icon"/>
      </b-col>
      <b-col lg cols="9" class="">
        <div class="pl-3 opacity-changed">
          <h3 class="mb-1">{{ group.title }}</h3>
          <div class="d-block d-lg-none">{{ group.instructor ? group.instructor.name : '' }}</div>
          <div class="text-muted small text-capitalize">{{ group.description }}</div>
        </div>
      </b-col>
      <b-col lg="2" cols="4" class="opacity-changed d-none d-lg-block">
        {{ group.instructor ? group.instructor.name : '' }}
      </b-col>
      <b-col lg="2" cols="auto" class="opacity-changed ml-auto mt-2 mt-lg-0">
        <LetterIcon :customTitle="group.students.length" class="student-icon xsm pointer"  :id="`group-${group.id}`"/>
        <b-popover :target="`group-${group.id}`" triggers="hover" placement="top">
          <div class="font-weight-bold pb-2">{{ $t('students_in_group') }}: <span class="text-primary">{{ group.students.length }}</span></div>
          <div v-for="s in group.students" :key="`stud-${s.id}`">
            <hr class="my-2"/>
            <router-link :to="`${roleLinkAppendix()}/students/${s.id}/history`" style="text-decoration: none">
              <b-row class="align-items-center">
                <b-col cols="auto">
                  <LetterIcon class="student-icon xsm pointer" :title="s.name"/>
                </b-col>
                <b-col col class="font-weight-bolder">{{ s.name }}</b-col>
              </b-row>
            </router-link>
          </div>
        </b-popover>
      </b-col>
      <b-col lg="auto" cols="auto" class="mt-2 mt-lg-0">
        <div class="text-right">
          <b-dropdown right no-caret menu-class="noscrollbar" variant="link" class="d-inline-block pointer">
            <template v-slot:button-content>
              <MoreSvg class="d-inline-block"/>
            </template>
            <b-dropdown-item @click="edit">{{ $t('edit') }}</b-dropdown-item>
            <!--            <b-dropdown-item v-if="student.student_status == 'active'" @click="passwordReset">{{ $t('reset_password') }}</b-dropdown-item>-->
            <!--            <b-dropdown-item v-if="$store.state.user.can_admin_payments && student.student_status == 'active'" @click="addPayment">{{ $t('add_payment') }}</b-dropdown-item>-->
            <!--            <b-dropdown-item v-if="student.blocked" @click="resendInv(student.id)">{{ $t('resend_invite') }}</b-dropdown-item>-->
            <!--            <b-dropdown-item v-if="student.student_status == 'active'" @click="pauseStudent">{{ $t('pause_student') }}</b-dropdown-item>-->
            <!--            <b-dropdown-item v-if="student.student_status == 'paused'" @click="activateStudent">{{ $t('activate_student') }}</b-dropdown-item>-->
            <!--            <b-dropdown-item v-if="student.student_status == 'paused'" @click="archiveStudent">{{ $t('archive_student') }}</b-dropdown-item>-->
            <!--            <b-dropdown-item @click="addNote">{{ $t('add_note') }}</b-dropdown-item>-->
            <!--            <b-dropdown-item v-if="student.student_status == 'active'" :to="{ name: 'Schedule', query: { students: student.id }}">{{ $t('schedule') }}</b-dropdown-item>-->
            <b-dropdown-item @click="deleteGroup" >{{ $t('delete') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LetterIcon from '@/views/parts/general/LetterIcon'
import MoreSvg from '@/assets/svg/more.svg'
import {StudentsService} from "../../services/api.service"

export default {
  name: "GroupTeaser",
  components: {
    LetterIcon,
    MoreSvg
  },
  props: {
    group: Object
  },
  methods: {
    edit() {
      this.$emit('edit', this.group)
    },
    deleteGroup() {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        StudentsService.deleteGroup(this.group.id).then(() => {
          this.$success(this.$t('group_deleted'))
          this.$emit('refresh')
        }).catch((err) => {
          this.errResponse(err)
        })
      })
    }
  }
}
</script>
